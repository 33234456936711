* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    border: medium none;
    margin: 0;
    padding: 0;

}

:focus {
    outline: none !important;
}

img,
fieldset {
    border: 0 none;
}

a {
    border: none;
    outline: none;
}

input[type="submit"],
select {
    -webkit-appearance: none;
}

img {
    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    vertical-align: middle;
}

a:hover {
    text-decoration: none;
    color: red;
}

.clear {
    clear: both;
}

ul::after {
    clear: both;
    content: "";
    display: block;
}

ul {
    list-style: none;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 27px;
    -webkit-font-smoothing: antialiased;
    background: #000;
    font-family: "Montserrat", sans-serif;
}

.top-boxes {
    background: #313131;
    padding: 8px;
    border: 1px solid #ffffff1a;
    border-radius: 5px;
    height: 100%;
}
.top-boxes button {
    background: #fff !important;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 10px;
    display: flex;
    justify-content: end;
    margin: 10px 0 0 auto;
    border-radius: 5px;
}
.buttons-bottom button {
    margin: 0 0 0 8px !important;
}
.buttons-bottom {
    display: flex;
    align-items: center;
    justify-content: end;
}
.top-area {
    padding-top: 6px;
    height: 100vh;
}

.top-boxes form label {
    color: #ffff;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
}

.form-control {
    background: #0000004d;
    border: 1px solid #ffffff40;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    font-size: 13px;
}

.form-control::placeholder {
    color: #fff;
    font-size: 10px;
}

.form-control:focus {
    color: #fff;
    background-color: #000;
    border-color: #ffffff54;
    outline: 0;
    box-shadow: unset;
}

.top-mid-area {
    padding: 0;
}
.css-qx5h3x-placeholder {
    font-size: 10px;
}
.top-boxes .css-1n6sfyn-MenuList {
    background: #000 !important;
    border: 1px solid #ffffff40;
    color: #fff;
    font-size: 12px;
}

.top-boxes .css-d7l1ni-option {
    background-color: #313131;
    cursor: pointer;
}

.selling-option-bottom .form-check {
    margin-right: 40px;
}

.selling-option-bottom {
    display: flex;
    align-items: center;
}

label.top-end-box.form-label {
    margin-bottom: 11px;
    font-weight: 500;
}

.transaction-logs .table>:not(caption)>*>* {
    color: #fff;
    background-color: #313131;
    padding: 6px 12px;
}

.transaction-logs {
    /* border: 1px solid #ffffff1a; */
    border-radius: 5px;
    overflow: auto;
    /* height: 100%; */
}

.transaction-logs .table {
    margin-bottom: 0;
}

.transaction-logs .table tr th {
    background: #494949;
    font-weight: 500;
    font-size: 10px;
    white-space: nowrap;
}

.transaction-logs .table tr td {
    font-size: 10px;
    white-space: nowrap;
    vertical-align: top;
}

.transaction-logs-left h6, .token-logs h6 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 11px;
}

td.sell-action {
    color: #ff5757 !important;
    font-weight: 500;
}

td.buy-action {
    color: #00a400 !important;
    font-weight: 500;
}

.selling-option-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

label.form-label {
    color: #fff;
    font-size: 15px;
    margin: 1px;
    font-weight: 400;
}
button.btn.btn-primary {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 15px;
}
button.btn.btn-secondary {
    font-size: 14px;
    padding: 4px 15px;
}
.selling-option-top button {
    background: #fff !important;
    border: none;
    padding: 3px 10px !important;
    color: #000 !important;
    font-size: 11px !important;
    font-weight: 600;
}
.top-boxes h5 {
    color: #fff;
    font-size: 12px;
}
.transaction-logs-left {
    background: #313131;
    padding: 10px;
    border: 1px solid #ffffff1a;
    border-radius: 5px;
}
.selling-option-top h6 {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 0;
}
.selling-top-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.css-1dimb5e-singleValue {
    color: hsl(0deg 0% 100%) !important;
}

.form-switch .form-check-input {
    cursor: pointer;
}

/* td.token-front {
    width: 67%;
    white-space: unset !important;
} */
.token-logs-top {
    display: flex;
    justify-content: space-between;
}

/* .transaction-body table {
    height: 100%;
} */

.transaction-body {
    overflow: auto;
    position: relative;
}
td.no-transaction-area {
    text-align: center;
    color: #fff;
    padding: 110px 0;
}
.transaction-body .table>thead {
    vertical-align: bottom;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 9;
}
td.sell-btn {
    text-align: end;
}
td.sell-btn button {
    background: #fff !important;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 10px;
    margin: 0 0 0 auto;
    border-radius: 4px;
    color: #000 !important;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ababab;
}

::-webkit-scrollbar-thumb {
    background-color: #1a1919;
    outline: 1px solid #1a1919;
}

/* .transaction-logs.snipperbot-transaction .table tr th,
.transaction-logs.snipperbot-transaction .table tr td {
    width: 20%;
} */

td.good-tokens {
    color: #06a400 !important;
}

td.bad-tokens {
    color: #ff5446 !important;
}

td.neutral-tokens {
    color: #ffc816 !important;
}

i.far.fa-copy {
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
}

.token-score {
    text-align: end;
}
.transaction-logs.snipperbot-transaction.transaction-body.bottom-table {
    min-height: 500px;
}
.transaction-logs.transaction-body.token-mis-area.mb-3 {
    border: none;
}
.transaction-logs .table>:not(caption)>*>* {
    color: #fff;
    background-color: #313131;
    padding: 5px 12px;
}

.css-10wo9uf-option:active {
    background-color: #313131 !important;
}

  tr.latest_token {
    border-bottom: 1px solid #ffffff3d;
}
tr.latest_token td {
    animation: blinkingBackground 2s infinite;
    
}

@keyframes blinkingBackground {
    0% {
        background-color: #000;
    }

    25% {
        background-color: #494949;
    }

    50% {
        background-color: #000;
    }

    75% {
        background-color: #494949;
    }

    100% {
        background-color: #000;
    }
}
.token-address-area {
    display: flex;
    align-items: center;
}
.transaction-body tr {
    background: #313131;
}
.token-address-area h6 p {
    font-size: 11px;
    font-weight: 300;
}

.token-address-area h6 p {
    margin-right: 7px;
}

.token-address-area h6 {
    margin: 0 0 0 10px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
}

.modal-content {
    background: #000;
    border: 1px solid #ffffffa3;
    border-radius: 30px;
}
.modal-header {
    border-bottom: none;
}
.modal-footer {
    border-top: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
    background: #222222;
    border: 1px solid #5a5a5adb;
    font-size: 14px;
    padding: 8px;
}
.modal-header {
    padding-bottom: 0;
}
.modal-title.h4 {
    color: #fff;
    font-size: 19px;
}
.manual-sell-popup label.form-label {
    font-size: 13px;
    color: #fff;
    margin: 0;
    font-weight: 300;
}
.manual-sell-popup .modal-footer {
    justify-content: center;
    padding-top: 0;
}
.manual-sell-popup button.btn.btn-secondary {
    background: #fff !important;
    color: #000 !important;
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 28px;
    border-radius: 4;
}
.token-body-bottom {
    height: 170px !important;
}
button.btn-close {
    filter: invert(1);
    opacity: unset;
    font-size: 14px;
}
.btn-close:focus {
    box-shadow: unset !important;
}
h6.main-wallet-top i {
    font-size: 14px;
}
h6.main-wallet-top {
    background: #fff;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 10px;
    border-radius: 5px;
    display: flex;
    margin: 0 0 7px 7px;
    color: #000;
    font-weight: 600;
    align-items: center;
}

h6.main-wallet-top img {
    width: 22px;
    margin-left: 7px;
}
h6.main-wallet-top span {
    margin-left: 6px;
    font-size: 11px;
    font-weight: 800;
}
.telegram-inner p {
    color: #fff;
    font-size: 13px;
    font-weight: 300;
}

.telegram-inner {
    max-height: 885px;
    overflow: auto;
    background: #313131;
    padding: 10px;
    border-radius: 5px;
}

.telegram-messages-inner p {
    font-size: 10px;
    word-wrap: break-word;
    line-height: 16px;
}
.telegram-messages-inner {
    background: #494949;
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 12px 0;
}
.login-area .row {
    align-items: center;
    height: 100vh;
}
.login-inner-area {
    background: #313131;
    padding: 50px;
    border: 1px solid #ffffff1a;
    border-radius: 40px;
    box-shadow: inset 0 5px 15px hsl(0deg 0% 64%/12%);
}
.login-inner-area h2 {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
}
.login-inner-area label {
    color: #fff;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 500;
}
.login-inner-area button {
    display: flex;
    margin: 40px auto 0;
    background: #fff !important;
    font-size: 15px;
    font-weight: 600;
    padding: 11px 40px;
    border-radius: 10px;
}
.login-inner-area input {
    padding: 12px;
}
.login-top h1 {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 33px;
    margin-bottom: 30px;
    font-weight: 700;
}
.login-area {
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.css-1nmdiq5-menu {
    background-color: rgb(0 0 0) !important;
}
.css-qr46ko {
    color: #fff !important;
    font-size: 12px;
}
td.token-front.score-board-right {
    text-align: end;
}
.telegram-messages-inner h6 {
    color: #ffffffbd;
    font-size: 10px;
   text-align: end;
    line-height: 5px;
    font-weight: 500;
}
.error {
    color: red !important;
}
.head-top-right {
    display: flex;
    justify-content: end;
}
.cursor-pointer {
    cursor: pointer;
}

.manual-sell-popup button.btn.btn-secondary {
    width: 110px;
    min-width: 110px;
}
.stop-loss-percentage.form-label input#custom-switch {
    margin: 7px 6px 0 0;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.stop-loss-percentage.form-label {
    display: flex;
    align-items: center;
}
.form-control:disabled {
    background-color: #e9ecef54;
    opacity: 1;
    cursor: not-allowed;
}
.amount-range {
    padding: 0;
    height: 3px;
    background: #fff;
    margin-top: 6px;
}
.amount-range:focus {
    color: #fff;
    background-color: #fff;
    border-color: #ffffff54;
    outline: 0;
    box-shadow: unset;
}
button.add-token-btn {
    margin-top: 0;
    font-size: 11px !important;
    padding: 1px 8px !important;
}
.react-switch-bg {
    height: 20px !important;
    width: 50px !important;
}
.react-switch-handle {
    width: 20px !important;
    height: 20px !important;
    top: 0px !important;
}
.react-switch-bg svg {
    top: 0px !important;
    width: 20px !important;
    height: 20px !important;
    right: 6px;
}
.transaction-logs.transaction-body.token-mis-area.token-address {
    height: 300px;
    overflow: auto;
}
.transaction-logs.transaction-body.token-mis-area.transaction-section-bottom {
    height: 380px;
    overflow: auto;
}
/* media-query */

@media (max-width: 1700px) {
    .transaction-body {
        height: 460px;
        overflow: auto;
    }
    .telegram-inner {
        max-height: 740px;
        overflow: auto;
    }
    .transaction-logs.transaction-body.token-mis-area {
        height: 245px;
    }
}
@media (max-width: 1500px) {
    .transaction-logs.transaction-body.token-mis-area.transaction-section-bottom {
        height: 370px;
        overflow: auto;
    }
    .transaction-body {
        height: 465px;
        overflow: auto;
    }
    .telegram-inner {
        max-height: 750px;
        overflow: auto;
    }
    .transaction-logs.transaction-body.token-mis-area {
        height: 250px;
    }
}
@media (max-width: 1400px) {
    .top-boxes form label {
        font-size: 10px;
    }
    .selling-option-top h6 {
        font-size: 10px;
        margin-right: 6px;
    }
    .top-boxes h5 {
        font-size: 10px;
    }
    .transaction-body {
        height: 460px;
        overflow: auto;
    }
}
@media (max-width: 1200px) {
    .telegram-message {
        margin-bottom: 20px;
    }
}
@media (max-width: 991px) {
    .top-boxes form label {
        font-size: 9px;
    }
}

@media (max-width: 767px) {

    .selling-top-right {
        margin-bottom: 10px;
    }
    }
@media (max-width: 575px) {
    .padding-right {
        padding-right: 13px;
    }
    .selling-inner.profit-percentage {
        margin-top: 0;
    }
    .top-boxes button {
        margin-top: 7px;
    }
 
}

@media (max-width: 480px) {
    .transaction-logs-left h6, .token-logs h6 {
        line-height: 20px;
    }
    .transaction-logs .table tr td {
        font-size: 9px;
    }
    .top-boxes h5 {
        color: #fff;
    }
    .login-inner-area {
        background: #313131;
        padding: 20px;
        border: 1px solid #ffffff1a;
        border-radius: 30px;
    }
    .login-top h1 {
        font-size: 28px;
    }
    .login-inner-area h2 {
        font-size: 25px;
    }
}